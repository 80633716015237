import * as React from "react";
import {
    Box,
    Flex,
    HStack,
    Link,
    IconButton,
    useDisclosure,
    useColorModeValue,
    Stack,
} from '@chakra-ui/react';

import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import {graphql, useStaticQuery} from "gatsby";

//const Links = ['Culture', 'Entertainment', 'Lifestyle', 'News', 'Photos', 'Celebrity', 'Vintage'];

const NavLink = ({ children }, { children: ReactNode }) => (
    <Link
        px={2}
        py={1}
        fontWeight={'bold'}
        rounded={'md'}
        _hover={{
            textDecoration: 'none',
            bg: useColorModeValue('gray.200', 'gray.700'),
        }}
        to={"/categories/" + children.toLowerCase()}
        href={"/categories/" + children.toLowerCase()}>
        {children}
    </Link>
);

const Navigation = () => {

    const { isOpen, onOpen, onClose } = useDisclosure();

    const catData = useStaticQuery(graphql`
        query headerQueryAndHeaderQueryAndHeaderQueryAndHeaderQuery {
          site {
            siteMetadata {
              categories
            }
          }  
        }
    `);

    const Cats = catData.site.siteMetadata.categories;

    return (
        <Box bg={useColorModeValue('white.100', 'white.100')} px={4} rounded={'md'} >
            <Flex marginRight={'auto'} marginLeft={'auto'} h={16} alignItems={'center'} justifyContent={'space-between'}>
                <IconButton
                    size={'md'}
                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    aria-label={'Open Menu'}
                    display={{ md: 'none' }}
                    onClick={isOpen ? onClose : onOpen}
                />
                <HStack spacing={7} alignItems={'center'}>
                    <HStack
                        as={'nav'}
                        spacing={4}
                        display={{ base: 'none', md: 'flex' }}>
                        {Cats.map((link) => (
                            <NavLink key={link}>{link}</NavLink>
                        ))}
                    </HStack>
                </HStack>
            </Flex>

            {isOpen ? (
                <Box pb={4} display={{ md: 'none' }}>
                    <Stack as={'nav'} spacing={4}>
                        {Cats.map((link) => (
                            <NavLink key={link}>{link}</NavLink>
                        ))}
                    </Stack>
                </Box>
            ) : null}
        </Box>

    );
};

export default Navigation;

