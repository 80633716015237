import * as React from "react";
import { graphql } from "gatsby";
import Tile from "../../components/tile";
import Footer from "../../components/footer";
import Helmet from "react-helmet"
import { Box, Container, SimpleGrid} from "@chakra-ui/react";
import Navigation from "../../components/navigation";


function Category(props) {

    const siteData = props.data.site.siteMetadata;
    const siteLogo = `/logos/${siteData.logo}`;
    const tileData = props.data.allSlideshowsJson.nodes;

    return (

        <Box p={4}>
            <Helmet>
                <title>{siteData.title} - {tileData[0].category}</title>
            </Helmet>
            <Container mt={2} maxW="container.lg">
                <div style={{ float: `left`, marginRight: `30px`, marginLeft: `40px`, marginTop: `20px`}}>
                    <a href="/"><img src={siteLogo} alt={siteData.title} width={160} /></a>
                </div>
                <Navigation/>
            </Container>
            <Container maxW={'6xl'} mt={70}>
                <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 3  }}
                            spacingX={5}
                            spacingY={5}>
                    {tileData.map((tile, i) => {
                        return <Tile key={i} tile={tile} />;
                    })}
                </SimpleGrid>
            </Container>
            <Footer site={siteData}/>
        </Box>
    );
}

export default Category;

export const query = graphql`
  query ($category: String!) {
    site {
      siteMetadata {
        description
        siteUrl
        title
        logo
      }
    }
    allSlideshowsJson(filter: {category: {eq: $category}}) {
      nodes {
        slug
        title
        category
        slides {
          category
          imageTitle
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
